<template>
    <section class="section image-section">
      <div class="inner-section inner-section-grid">
        <div class="inner-section-title">
          <HeadingTitle :title="title" :block="true">
            <template v-slot:before>
              <p class="header-grey">
                ELECTRICITY
              </p>
            </template>
          </HeadingTitle>
        </div>
        <div class="inner-section-grid-second-div inner-section-img">
          <img v-if="device_width > 1024" :src="require(`@/assets/img/img-main-emf${index}.svg`)" alt="" />
          <img v-else :src="require(`@/assets/img/m_img-main-emf${index}.svg`)" alt="" />
        </div>
      </div>
    </section>
</template>

<script>
import { onMounted, ref } from "vue";
import HeadingTitle from "./HeadingTitle.vue";
export default {
  name: "img-flow",
  components: {
    HeadingTitle,
  },
  props: {
    title: {
      type: String,
      default: () => ""
    },
    index: {
      type: Number,
      default: () => 1
    } 
  },
  setup() {
    let device_width = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener('resize', () => {
          device_width.value = window.innerWidth;
      })
    })
    return {
      device_width
    };
  },
};
</script>