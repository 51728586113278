<template>
  <section class="section section-vision">
    <div class="inner-section inner-section-grid">
      <div class="inner-section-title inner-section-title-nav section-vision-btn">
        <Heading title="MISSION & VISION" block>
          <template v-slot:before>
            <div class="before">
              <span>OUR</span>
            </div>
          </template>
        </Heading>
        <div class="btn-wrapper" v-if="device_width < 720">
          <div class="btn-vision-prev">
            <img src="@/assets/img/btn-arrow.svg" alt="">
          </div>
          <div class="btn-vision-next">
            <img src="@/assets/img/btn-arrow.svg" alt="">
          </div>
        </div>
      </div>
      <div v-if="device_width >= 720" class="vision-web-container">
        <div class="vision-web-title">
          <h3 v-for="v in visions" :key="v">
            {{ v.title }}
          </h3>
        </div>
        <div class="vision-web-content">
          <p v-for="v in visions" :key="v">{{ v.content }}</p>
        </div>
      </div>
      <swiper v-else class="vision-swiper" :navigation="{ nextEl: '.btn-vision-next', prevEl: '.btn-vision-prev' }"
        :pagination="device_width < 720 ? true : false" :slides-per-view="2.5" :space-between="50" :breakpoints="{
          '0': {
            slidesPerView: 1,
            spaceBetween: 60,
          },
          '720': {
            slidesPerView: 2.5,
            spaceBetween: 40,
            slidesOffsetBefore: 40
          },
        }" @swiper="onSwiper" @slideChange="onSlideChange">
        <swiper-slide v-for="v in visions" :key="v">
          <div class="slide-content">
            <div class="vision-slide">
              <h3 class="">
                {{ v.title }}
              </h3>
              <p>{{ v.content }}</p>
            </div>
          </div>
        </swiper-slide>
        <template v-slot:container-start>
          <div class="fade-dim" v-if="device_width > 1024">
          </div>
        </template>
      </swiper>
    </div>
    <div class="vision-devider" v-if="device_width > 1024"></div>
  </section>
</template>

<script>
import SwiperCore, { Navigation, Pagination } from "swiper";
import Heading from "../../Elements/HeadingTitle.vue"
import { Swiper, SwiperSlide } from "swiper/vue";
import { reactive, ref, onMounted } from "vue";

SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Heading,
    Swiper,
    SwiperSlide,
  },
  name: "vision",
  setup() {
    const isEnd = ref(false);
    const onSwiper = swiper => {
      console.log(swiper.isEnd);
    };
    const onSlideChange = (swiper) => {
      isEnd.value = swiper.isEnd;
      console.log(swiper.isEnd);
    };

    const visions = reactive([
      {
        title: "エネルギーの生産を消費者の手に取り戻す",
        content: "これまでのエネルギー市場では、少数の事業者が生産と流通を独占し、多くの消費者は生産から遠ざけられてきました。このような不平等な構造を転換し、消費者がエネルギー生産の手段を所有することによる、エネルギー民主主義に向けたエネルギー転換を実現することが、私たちの使命です。",
      },
      {
        title: "新しいエネルギー市場の創造による気候変動問題の解決",
        content: `多くの市民が共同で小規模の再生可能エネルギー発電所を所有する、こうした新しいエネルギーのあり方を形成し、これにより気候変動問題解決され、持続可能な地域と地球の未来をつくることを目指しています。`,
      },
    ]);
    let device_width = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener('resize', () => {
        device_width.value = window.innerWidth;
      })
    })
    return {
      visions,
      isEnd,
      device_width,
      onSwiper,
      onSlideChange,
    };
  },
};
</script>
<style lang="scss" scoped>
.vision-slide {

  p,
  h3 {
    word-break: normal !important;
  }

  h3 {
    line-height: 38px;
  }

  p {
    line-height: 26px;
  }
}

.vision-web-title {
  display: flex;
  gap: 60px;

  h3 {
    width: 500px;
    font-size: 48px;
    font-weight: 700;
    line-height: 62px;
  }
}

.vision-web-content {
  display: flex;
  gap: 60px;

  p {
    width: 500px;
    font-size: 20px;
    padding-top: 30px;
    line-height: 30px;
  }
}

@media screen and (max-width: 1025px) {
  .vision-web-container {
    padding-top: 30px;
  }

  .vision-web-title {
    justify-content: center;
    text-align: left;

    h3 {
      width: 335px;
      font-size: 28px;
      padding-top: 20px;
      line-height: 38px;
    }
  }

  .vision-web-content {
    justify-content: center;
    text-align: left;

    p {
      width: 335px;
      font-size: 16px;
      padding-top: 20px;
      line-height: 26px;
    }
  }
}
</style>
