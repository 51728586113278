<template>
    <div>
        <div class="content">
            株式会社H-energy（以下当社という。）の運営するWebサイト（以下「当サイト」といいます）をご利用いただき誠にありがとうございます。当サイトを快適かつ適切にご利用いただくため、下記内容をご一読くださいますよう、お願いいたします
        </div>
        <div class="detail">
            <p><span>•</span>Webブラウザなど閲覧環境</p>
            <p><span>•</span>当サイトでは以下の環境での閲覧を推奨しております。</p>
            <p class="tab-sm"><span>•</span>Windows Edge 84.0 以降、Chrome 84.0 以降、Firefox 78.0 以降</p>
            <p class="tab-sm"><span>•</span>Mac OS Safari 13.6.1 以降</p>
            <p class="tab-sm"><span>•</span>iOS Safari iOS 12.4.8 以降</p>
            <p class="tab-sm"><span>•</span>Android Chrome Android 83.0 以降</p>
            <p class="tab-sm"><span>•</span>iPhone Safari iOS 12.4.8 以降、Android Chrome Android 83.0 以降</p>
            <p><span>•</span>推奨環境以外でのご利用や、推奨環境下でもお客様のWebブラウザの設定や、機種、メーカー、その他のアプリケーションのインストール状況やバージョンの組合せなどにより、ページが正しく表示されない場合がございます。また、当サイトでは、JavaScriptを使用しているページがございます。ご利用のブラウザでJavaScript機能を無効にされている場合、正しく機能しない、または正しく表示されないことがありますので、当サイトをご利用いただく際は、ブラウザの設定でJavaScriptを有効にしてください。
            </p>
            <p><span>•</span>ご利用上の注意（掲載情報についての免責事項）</p>
            <p><span>•</span>当サイトの掲載内容については十分注意を払って確認しておりますが、その内容の完全性、正確性、有用性、安全性等については、いかなる保証を行うものでもありません。利用者がこのサイトを利用することにより生じた一切の損害等につき、いかなる責任も負いませんので予めご了承ください。最新かつ正確な情報を提供するため、何時でも当サイト上の不正確な記載や誤植を修正、または内容を更新することがあります。事前の予告なしに、情報提供の休止および再開を行うこともありますので予めご了承ください。また、当サイトのアドレスはトップページを含めて事前に予告することなく変更する場合があります。当サイトのアドレス変更により発生するリンク切れ等、表示に関わる不具合その他一切の影響について、当社はその責任を負いませんので予めご了承ください。
            </p>
            <p><span>•</span>著作権について</p>
            <p><span>•</span>当サイトに掲載されているコンテンツ（文章、画像、写真、動画、ロゴ、プログラム等）に関する著作権およびその他の権利は、当社または当社にその使用を認めた権利者に帰属します。権利者に無断で転用・複製などをすることはできません。
            </p>
            <p><span>•</span>商標等について</p>
            <p><span>•</span>当サイトに掲載されている商標、ロゴ、サービスマーク（以下総称して「商標等」といいます）は、当社または当社にその使用を認めた権利者に権利が帰属します。これらの商標等を権利者に無断で使用することはできません。
            </p>
            <p><span>•</span>外部サイトへのリンクについて</p>
            <p><span>•</span>当サイトには、当社以外のウェブサイト（以下「外部サイト」といいます）へのリンクが含まれています。当サイトからリンクしている外部サイトは、各サイト運営者の責任において管理・運営されており、それらの外部サイトは当社の管理下にありません。当社は、お客様が外部サイトをご利用になったことにより生じたいかなる損害についても責任を負いかねますので、予めご了承ください。
            </p>
            <p><span>•</span>当サイトへのリンクの注意事項</p>
            <p class="tab-sm"><span>•</span>当社の有する権利を侵害し、業務を妨害し、または当社のイメージを損なうようなリンクを当サイトへ設定することは固く禁じております。</p>
            <p class="tab-sm"><span>•</span>フレーム内での表示など、当サイトへのリンクとなることが不明瞭になる方法でのリンク設定はご遠慮ください。</p>
        </div>
    </div>
</template>
<script>
export default {
    components: {
    },
    setup() {
        return {
        };
    },
};
</script>
<style lang="scss" scoped>
.content {
    padding-bottom: 30px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}

.detail {
    font-size: 16px;
    line-height: 26px;
    height: 50vh;
    overflow-y: scroll;

    p {
        display: flex;
        gap: 4px;
    }
}

.tab-sm {
    padding-left: 16px;
}</style>
