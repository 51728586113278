<template>
  <main>
    <div v-if="country_code === 'KR'">
      <Header v-if="country_code === 'KR'"></Header>
      <MainTop id="" />
      <Vision id="our_vision" />
      <ImageFlow title="MARKET FLOW" />
      <Mohaet id="service" />
      <RE100Service />
      <ImageFlow title="MANAGEMENT FLOW" :index="2" />
      <SolarKimService />
      <Dershare id="solutions" />
      <Staffs id="staff" />
      <History id="history" />
      <Recruit id="recruit" />
      <Footer v-if="country_code === 'KR'"></Footer>
    </div>
    <div v-if="country_code === 'JP'">
      <Header_JP v-if="country_code === 'JP'"></Header_JP>
      <JapanContents></JapanContents>
      <Footer_JP v-if="country_code === 'JP'"></Footer_JP>
    </div>
  </main>
</template>

<script>
import Header from './Nav/Header.vue'
import Footer from './Nav/Footer.vue'
import Header_JP from './Japan/Nav/Header.vue'
import Footer_JP from './Japan/Nav/Footer.vue'
import MainTop from "./Section/Maintop.vue";
import Vision from "./Section/Vision.vue";
import ImageFlow from "./Elements/ImageFlow.vue"
import Mohaet from "./Section/MohaetSolutions.vue";
import RE100Service from "./Section/RE100Service.vue";
import Dershare from "./Section/DershareSolutions.vue";
import SolarKimService from "./Section/SolarKimService.vue";
import Staffs from "./Section/Staffs.vue";
import History from "./Section/History.vue";
import Recruit from "./Section/Recruit.vue";
import JapanContents from './Japan/JapanContents.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  name: "main",
  components: {
    Header,
    Footer,
    Header_JP,
    Footer_JP,
    MainTop,
    Vision,
    ImageFlow,
    Mohaet,
    RE100Service,
    Dershare,
    SolarKimService,
    Staffs,
    History,
    Recruit,
    JapanContents
  },
  setup() {
    const store = useStore();
    const change_width = store.commit('change_width', window.innerWidth);
    const country_code = computed(() => {
      const language = navigator.language || navigator.languages?.[0];
      console.log("언어: ", navigator.language, navigator.languages);
      if (language === "ja") return "JP";
      return "KR";
    })
    window.addEventListener('resize', () => {
      change_width
    });

    return {
      store,
      country_code,
      change_width,
    };
  },
};
</script>
<style lang="scss">
@import '../assets/styles/style.scss';
</style>