<template>
    <section class="section service-section">
        <div class="inner-section inner-section-grid">
            <div class="inner-section-title">
                <Heading title="알뜰전기요금제">
                    <template v-slot:after>
                        <span class="after">
                            SERVICE
                        </span>
                    </template>
                </Heading>
                <div class="inner-service-logo">
                    <div class="service-logo2">
                        <img src="@/assets/img/img-power-logo.svg" alt="">
                    </div>
                </div>
            </div>
            <div class="inner-section-grid-second-div inner-section-content">
                <div class="solution" v-for="mo in mo_solutions" :key="mo">
                    <Solution :title="mo.title" :eng="mo.eng" :subtitle="mo.subtitle" :mobile_subtitle="mo.mobile_subtitle"
                        :content_01="mo.content_01" :content_02="mo.content_02" :content_03="mo.content_03" />
                    <div v-if="mo.title === '알뜰전기요금제'" class="flex">
                        <ServiceButton label="알뜰전기요금제 바로가기" link="https://m-powerbill.com/">
                            <template v-slot:icon>
                                <img src="@/assets/img/arrow_right.svg" alt="" class="service">
                            </template>
                        </ServiceButton>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Heading from "../Elements/HeadingTitle.vue"
import ServiceButton from "../Elements/ServiceButton.vue"
import Solution from "../Elements/Solution.vue"
import { reactive } from "vue";

export default {
    components: {
        Heading,
        ServiceButton,
        Solution
    },
    name: "mohaet-solutions",
    setup() {
        const mo_solutions = reactive([
            {
                title: "알뜰전기요금제",
                subtitle: `세상에 없던 전기구독 서비스,`,
                mobile_subtitle: "세상에 없던 전기구독 서비스",
                content_01: `알뜰전기요금제는 친환경 전기로 주택 전기 요금을 절감하는 현명한 방법 입니다. 한국 최초로 태양광 발전소에서 생산된 전기로 우리집 전기요금을 절감하는 규제 특례를 시행합니다.`,
                content_02: `내 집 지붕 위에 태양광 발전소를 짓지 않고도 멀리 있는 발전소와의 전력 계약을 통해 친환경 전기를 직접 생산한 것과 같은 혜택을 받아보세요.`,
            },
        ]);
        return {
            mo_solutions,
        };
    },
};
</script>