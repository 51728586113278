<template>
    <main>
        <div class="japan-page">
            <MainTop />
            <Vision id="our_vision" />
            <ImageFlow title="MARKET FLOW" />
            <ImageFlow grey_title="H Energyがつくる" :title="title" :multi_title="multi_title" :index="2" heading_content />
            <ImageFlow title="MANAGEMENT FLOW" :index_array="[3, 4]" />
            <Mohaet1 id="service" />
            <Mohaet2 />
            <SolarKimService />
            <Dershare id="solutions" />
            <Staffs id="people" />
            <History id="history" />
            <Contact id="contact" />
        </div>
    </main>
</template>

<script>
import MainTop from "./Section/Maintop.vue";
import Vision from "./Section/Vision.vue";
import ImageFlow from "./Elements/ImageFlow.vue"
import Mohaet1 from "./Section/MohaetService1.vue";
import Mohaet2 from "./Section/MohaetService2.vue";
import Dershare from "./Section/DershareSolutions.vue";
import SolarKimService from "./Section/SolarKimService.vue";
import Staffs from "./Section/Staffs.vue";
import History from "./Section/History.vue";
import Contact from "./Section/Contact.vue";
import { computed, onMounted, ref } from "vue";

export default {
    name: "main",
    components: {
        MainTop,
        Vision,
        ImageFlow,
        Mohaet1,
        Mohaet2,
        Dershare,
        SolarKimService,
        Staffs,
        History,
        Contact,
    },
    setup() {
        let device_width = ref(window.innerWidth);
        onMounted(() => {
            window.addEventListener('resize', () => {
                device_width.value = window.innerWidth;
            })
        })

        const title = computed(() => {
            return device_width.value > 1024 ? "地域エネルギーの" : "地域エネルギーのエコシステム"
        })
        const multi_title = computed(() => {
            return device_width.value > 1024 ? "エコシステム" : ""
        })
        return {
            title,
            multi_title,
            device_width,
        };
    },
};
</script>
<style lang="scss">
.japan-page * {
    font-family: "Pretendard JP";
}
</style>