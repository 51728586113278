<template>
  <section class="section section-history">
    <div class="inner-section inner-section-grid">
      <div class="inner-section-title">
        <HeadingTitle title="HISTORY">
          <template v-slot:before>
            <div class="before">
              <span>H</span>
            </div>
          </template>
        </HeadingTitle>
      </div>
      <div class="inner-section-grid-second-div inner-section-history">
        <div class="history-container" v-for="row in history" :key="row">
          <div class="history-year">
            <h3>{{ row.year }}</h3>
          </div>
          <div class="history-item">
            <div class="history-li" v-for="li in row.list" :key="li">
              <div class="history-li-item grid-w-60">
                <p class="month">{{ li.month }}</p>
                <p class="content">{{ li.content }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { reactive } from "vue";
import HeadingTitle from "../../Elements/HeadingTitle.vue";

export default {
  components: {
    HeadingTitle,
  },
  setup() {

    const history = reactive([
      {
        year: "2018", list: [
          { month: "3月", content: `設立（2018.03.27）` },
          { month: "5月", content: `TIPSプログラム選定（分散資源最適運用及び電力仲介サービス開発）` },
          { month: "9月", content: `DERShare.krプラットフォーム起動（分散リソース最適運用、VPPサービス開始）` },
          { month: "12月", content: `銅雀区市民仮想発電所事業開始（エネルギー協同組合協力事業）` },
        ]
      },
      {
        year: "2019", list: [
          { month: "4月", content: `Series A 投資誘致(Enlight Ventures)` },
          { month: "12月", content: `韓国東西発電（株）エネルギー新事業協力利益共有パートナー（5年間）` },
        ]
      },
      {
        year: "2020", list: [
          { month: "1月", content: `蔚山市民家商発電所事業開始` },
          { month: "3月", content: `慶北市民家商発電所事業開始` },
          { month: "6月", content: `事業化課題選定（エネルギー技術水溶性向上及び事業促進事業）` },
          { month: "9月", content: `韓国国会「2020年第4次産業革命Power Korea大戦」スマートエネルギー共有プラットフォーム賞` },
          { month: "11月", content: `慶尚北道知事表彰受賞` },
          { month: "12月", content: `Mohaet事業開始` },
        ]
      },
      {
        year: "2021", list: [
          { month: "3月", content: `「小規模太陽光電力取引プラットフォーム」実証特例(慶北 / 蔚山地域内）」` },
          { month: "6月", content: `KB Invest、ダダムイInvestから21億ウォン規模シリーズA投資誘致	` },
          { month: "9月", content: `「蔚山市民家相発電所竣工式」` },
          { month: "10月", content: `DER Share Smart Managerサービス開始、「慶北ウリ集RE100慶北道2021積極行政優秀事例慶進大会大賞受賞」` },
        ]
      },
      {
        year: "2022", list: [
          { month: "5月", content: `産業通商資源部「地域エネルギー新産業活性化支援事業支援対象選定」` },
          { month: "6月", content: `KimTaeYangサービス公開` },
          { month: "7月", content: `Mohaet会員1万人突破` },
          { month: "9月", content: `キャンペーンRE100thINGS（アル・イ・バックニップス）展開` },
          { month: "9月", content: `小規模太陽光電力取引プラットフォーム規制サンドボックス消失特例（新技術等実証特例）韓国電力公社業務協約` },
        ]
      },
      {
        year: "2023", list: [
          { month: "2月", content: `「2023大韓民国産業大賞」 K-Startup 大賞3年連続受賞（マネートゥデイ）` }
        ]
      },
    ]);
    return {
      history,
    };
  },
};
</script>
<style lang="scss" scoped>
.grid-w-60 {
  grid-template-columns: 60px 1fr !important;
}

.history-li-item .content {
  word-break: normal !important;
}
</style>
