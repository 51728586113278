<template>
    <a
        class="btn-service"
        :class="is_footer ? 'btn-footer' : ''"
        :href="link"
        target="_blank"
    >
        <div class="label flex">
            <p v-html="label">
            </p>
        </div>
        <div class="icon">
            <slot name="icon"></slot>
        </div>
    </a>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
            default: () => ""
        },
        link: {
            type: String,
            default: () => ""
        },
        is_footer :{
            type: Boolean,
            default: () => false
        },
        width: {
            type: String,
            default: () => ""
        },
    },
    name: 'service-button',
    computed: {
        // grid_style: function () {
        //     return this.width ? `grid-template-columns: ${this.width} 70px` : "";
        // },
        // label_style: function () {
        //     return this.width ? `min-width: ${this.width};` : "";
        // }
    }
}
</script>