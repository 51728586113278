<template>
    <div
        class="video-section"
        id="youtube_video"
    >
        <div
            class="video-close"
            v-if="show_btn"
        >
            <div
                class="btn-youtube-close"
                v-if="device_width > 1024"
                @click="close_modal"
                @keydown.esc="close_modal"
                tabindex="0"
            >
                <p>Press <span>ESC</span> to exit fullscreen</p>
            </div>
            <div
                class="btn-youtube-close"
                v-else
                @click="close_modal"
            >
                <img
                    src="@/assets/img/close.svg"
                    alt=""
                >
            </div>
        </div>
        <div
            class="video-container"
            id="player"
        >
            <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/TSgD5nQJMAg?autoplay=1&mute=1"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
            ></iframe>
        </div>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue';
export default {
    emits: ['youtube'],
    props: {
        youtube: Boolean
    },
    setup(props, { emit }) {
        const show_btn = ref(false);
        const close_modal = () => {
            emit('youtube', false)
        }
        onMounted(() => {
            const close_with_esc = (e) => {
                if (e.code === "Escape") {
                    emit('youtube', false)
                }
            }
            document.addEventListener('keydown', close_with_esc);
        })
        setTimeout(() => {
            show_btn.value = true;
        }, 1000);
        let device_width = ref(window.innerWidth);
        window.addEventListener('resize', () => {
            device_width.value = window.innerWidth; // 현재 스크롤바 위치
        })
        return {
            show_btn,
            device_width,
            close_modal
        }
    },
}
</script>