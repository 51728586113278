<template>
    <div>
        <div class="content">
            株式会社H-energy（以下当社という。）の運営するWebサイト（以下「当サイト」といいます）をご利用いただき誠にありがとうございます。当サイトを快適かつ適切にご利用いただくため、下記内容をご一読くださいますよう、お願いいたします
        </div>
        <div class="detail">
            <p><span>•</span>Webブラウザなど閲覧環境</p>
            <p><span>•</span>当社は個人データについて、以下の利用目的のために利用いたします。</p>
            <p class="tab-sm"><span>•</span>当社のサービス・製品等に関する申込受付、提供、連絡および管理</p>
            <p class="tab-sm"><span>•</span>当社のサービス・製品等に関する申込受付、提供、連絡および管理に付帯・関連するすべての業務</p>
            <p class="tab-sm"><span>•</span> お客様からのお問い合わせへの対応、または当社からお客様へのお問い合わせ</p>
            <p class="tab-sm"><span>•</span> 当社サイトとその他のサービスに変更があったことのお客様への連絡</p>
            <p class="tab-sm"><span>•</span> 各種アフターサービスの提供</p>
            <p class="tab-sm"><span>•</span> 各種イベント/キャンペーンの案内、運営、管理および各種情報の提供</p>
            <p class="tab-sm"><span>•</span> プレゼントへの応募、賞品の発送</p>
            <p class="tab-sm"><span>•</span> 当社のサービス・製品に関するアンケートの実施</p>
            <p class="tab-sm"><span>•</span> 当社のサービス・製品の内容の改善や新たなサービス・新製品の開発</p>
            <p class="tab-sm"><span>•</span> 詐欺その他の金銭にかかわる犯罪を察知し防止するため</p>
            <p class="tab-sm"><span>•</span> 広告の配信状況の把握、広告効果の測定及び、行動ターゲティング広告の表示のため</p>
            <p><span>•</span>なお、当社が第三者に提供する分析結果に個人が特定されるような情報は含まれません。</p>
            <p><span>•</span>個人データの取得</p>
            <p><span>•</span>当社は、当社サイトご利用のお客様から以下の個人データを取得することがあります。</p>
            <p class="tab-sm"><span>•</span> 氏名、年齢、性別及び住所の詳細</p>
            <p class="tab-sm"><span>•</span> メールアドレス</p>
            <p class="tab-sm"><span>•</span> ユーザー名及びパスワード</p>
            <p class="tab-sm"><span>•</span> IPアドレス</p>
            <p class="tab-sm"><span>•</span> 当社とのお取引履歴及びその内容</p>
            <p class="tab-sm"><span>•</span> お客様にお届けしたメールの開封に関する情報</p>
            <p class="tab-sm"><span>•</span> お客様にお届けしたメール上でのお客様のＵＲＬクリックに関する情報</p>
            <p class="tab-sm"><span>•</span> お客様が当社サイトにアクセスした際にお使いのブラウザ、オペレーティングシステム、プラットフォームなどの情報</p>
            <p class="tab-sm"><span>•</span> ソーシャルメディア上の公開プロフィールデータ</p>

            <p><span>•</span>当社が個人データを収集するのは、当社サイトご利用のお客様による以下の行為があった場合です。</p>
            <p class="tab-sm"><span>•</span> 当社サイトにおいて登録をしていただく場合</p>
            <p class="tab-sm"><span>•</span> 製品の購入</p>
            <p class="tab-sm"><span>•</span> 各種イベント/キャンペーンへのお申し込み、アンケートへのご協力、プレゼント・懸賞へのご応募をいただく場合</p>
            <p class="tab-sm"><span>•</span> 当社サービスに関して当社にお問い合わせをいただく場合</p>
            <p class="tab-sm"><span>•</span> ソーシャルメディア上での写真投稿、コメント投稿</p>
            <p class="tab-sm"><span>•</span> その他当社サイトを利用する場合</p>
            <p><span>•</span>個人データの取扱いへの同意</p>
            <p><span>•</span>お客様は、本プライバシーポリシーに同意する意思表示を行うことにより、当社による個人データの取扱いに同意したこととなり、当社は、その同意に基づいて、お客様の個人データを取扱います。ただし、お客様はこの同意をいつでも変更することができます。16歳未満のお客様におかれましては、本サービスを利用するにあたっては、保護者の方に同意をしていただくか、同意に対して保護者の方の許可をいただくようお願いいたします。
            </p>
            <p><span>•</span>ソーシャルメディア（当社のアカウント）について</p>
            <p><span>•</span>各ソーシャルメディアに投稿された個人情報の取扱いは、各ソーシャルメディアのプライバシーポリシーに従います。</p>
            <p><span>•</span>個人データの第三者への非開示</p>
            <p><span>•</span>ご提供いただいた個人データは、法令・規則又は当社の定めるところにより例外として扱うことが認められる場合を除き、ご本人の同意なく第三者に開示又は提供いたしません。</p>
            <p><span>•</span>個人データの取扱いに関する請求について</p>
            <p><span>•</span>お客様は、関係法令の規定に従い当社の保持する個人データに関して確認、訂正・追加・削除、処理の制限、異議申立て及びデータ・ポータビリティに関する請求を行うことができます。また、お客様は、当社について又はお客様の住所の所在地について管轄権を有するデータ保護当局に対し、お客様の個人データの処理作業に関して異議を申し立てることができます。お客様が個人情報の開示をご希望される場合は、ご本人であることを確認させて頂いた上で、ご依頼の個人情報についてお知らせ致します。
            </p>
            <p><span>•</span>クッキー(Cookie)の利用</p>
            <p><span>•</span>当社サイト・モバイルアプリには、お客様が再度当社サイトを訪問された時に、お客様によりいっそう便利にご利用いただけるよう「クッキー」と呼ばれる技術を使用しているページがあります。「クッキー」はお客さまのコンピュータを識別する業界標準の技術です。お客様は、ブラウザの設定により「クッキー」の機能を無効にすることができますが、その結果当社サイト上のサービスの全部または一部がご利用できなくなることがあります。
            </p>
            <p><span>•</span>本プライバシーポリシーの変更ついて</p>
            <p><span>•</span>当社は、プライバシーポリシーの全部または一部を変更する場合があります。重要な変更がある場合には、本サービスが提供される当社サイトにおいて、分かりやすい方法でお知らせします。当社サイトご利用のお客様の責任において、定期的にご確認ください。
            </p>
            以上
        </div>
    </div>
</template>
<script>
export default {
    components: {
    },
    setup() {
        return {
        };
    },
};
</script>
<style lang="scss" scoped>
.content {
    padding-bottom: 30px;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}

.detail {
    font-size: 16px;
    line-height: 26px;
    height: 50vh;
    overflow-y: scroll;

    p {
        display: flex;
        gap: 4px;
    }
}

.tab-sm {
    padding-left: 16px;
}
</style>
