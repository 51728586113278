<template>
    <footer>
        <div class="footer-container">
            <div class="footer-logo">
                <img src="https://cdn.dershare.kr/henergy-web/h_logo.svg" alt="">
            </div>
            <div v-if="device_width > 1024" class="address">
                <address>
                    <dl>
                        <dt>사업자 등록번호</dt>
                        <dd>103-86-01108</dd>
                    </dl>
                    <dl>
                        <dt>대표</dt>
                        <dd>함일한</dd>
                    </dl>
                    <dl>
                        <dt>이메일</dt>
                        <dd><a href="mailto:contact@henergy.xyz">contact@henergy.xyz</a></dd>
                    </dl>
                    <dl>
                        <dt>사업관련문의</dt>
                        <dd>02-542-0327</dd>
                    </dl>
                </address>
                <address>
                    <dl>
                        <dt>본사</dt>
                        <dd>
                            경상북도 포항시 남구 지곡동 176 체인지업 그라운드 609 (주) 에이치에너지
                        </dd>
                    </dl>
                </address>
                <address>
                    <dl>
                        <dt>서울지점</dt>
                        <dd>
                            서울특별시 강남구 삼성동 1번지 천마빌딩 505호
                        </dd>
                    </dl>
                    <dl>
                        <dt>기업부설연구소</dt>
                        <dd>
                            서울특별시 강남구 삼성동 1번지 천마빌딩 507호
                        </dd>
                    </dl>
                </address>
            </div>
            <div v-else class="mobile-address">
                <address>
                    <dl v-for="com in company_info" :key="com">
                        <dt>{{ com.label }}</dt>
                        <dd v-if="com.label === '이메일'">
                            <a :href="`mailto:${com.content}`">{{ com.content }}</a>
                        </dd>
                        <dd v-else>
                            {{ com.content }}
                        </dd>
                    </dl>
                </address>
            </div>
        </div>

        <div class="footer-links">
            <div v-if="device_width < 1024">
                <ul>
                    <li class="footer-biz-link--img" v-for="li in biz_list" :key="li">
                        <a :href="li.link" target="_blank"><img :style="li.label === '김태양' ? 'height: 20px;' : ''" :src="require(`@/assets/img/${li.icon}`)"
                                :alt="li.label" /></a>
                    </li>
                </ul>
                <ul>
                    <li class="footer-sns-link--img" v-for="li in sns_list" :key="li">
                        <a :href="li.link" target="_blank"><img :style="li.label === '유튜브' ? 'height: 16px;' : ''"
                                :src="require(`@/assets/img/${li.icon}`)" :alt="li.label" /></a>
                    </li>
                </ul>
            </div>
            <div class="copyright">
                <p>© 주식회사 에이치에너지</p>
                <p>전화번호 및 이메일의 무단 수집을 금지합니다.</p>
            </div>
        </div>
    </footer>
</template>
<script>
import { ref, reactive } from "@vue/reactivity";
import { contact, links, company, bizs, sns } from "./nav";
export default {
    components: {
    },
    setup() {
        const contact_list = reactive(contact);
        const link_list = reactive(links);
        const biz_list = reactive(bizs);
        const sns_list = reactive(sns);
        const company_info = reactive(company)
        let device_width = ref(window.innerWidth);
        window.addEventListener('resize', () => {
            device_width.value = window.innerWidth;
        })
        return {
            device_width,
            contact_list,
            biz_list,
            company_info,
            link_list,
            sns_list
        };
    },
};
</script>
