<template>
  <section class="section-main">
    <swiper
      class="main-swiper"
      effect="fade"
      loop
      :speed="1000"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: false,
      }"
      :slides-per-view="1"
    >
      <swiper-slide v-for="item in 4" :key="item">
        <div class="inner-main">
          <div :class="`inner-main-image item-image-${item}`" :style="`height: ${fullscreen}px`">
            <div class="inner-main-box">
              <div class="box-item box-title">
                <h2>HAVE<br>YOUR ENERGY<br>& SHARE IT</h2>
              </div>
              <div class="box-item box-text">
                <div class="box-text-content" v-if="device_width > 1024">
                  <p>에너지 소비가 아닌<br>에너지 소유가 되도록!</p>
                  <p>기술로 에너지 생태계를<br>혁신해 나아갑니다</p>
                </div>
                <div class="box-text-content" v-else>
                  <p>{{ phrase }}</p>
                </div>
              </div>
              <div class="box-item box-btn">
                <div class="btn-youtube" @click="youtube = true">
                  <div class="arrow">
                  <img src="@/assets/img/ico-play.svg" alt="재생버튼">
                  </div>
                </div>
                <div class="text-wrap" v-if="device_width < 1025">
                  <div class="text-ceo">
                    <p>Henergy CEO Interview</p>
                    <p>Science Nonfiction | 2021</p></div>
                  </div>                  
              </div>
              <div class="box-item box-text" v-if="device_width > 1024">
                <div class="box-text-content text-ceo">
                    <p>2021. Henergy CEO Interview / Science Nonfiction</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <Youtube v-if="youtube" @youtube="close_modal" />
  </section>
</template>

<script>
import SwiperCore, { EffectFade, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import Youtube from "../Modal/Youtube.vue"
import { ref, reactive, onMounted, watch } from "vue";

SwiperCore.use([EffectFade, Autoplay])

export default {
  components: {
    Swiper,
    SwiperSlide,
    Youtube
  },
  setup() {
    const fullscreen = ref(window.innerHeight);
    const main_images = reactive([
      { img: "img-mainvs01" },
      { img: "img-mainvs02" },
      { img: "img-mainvs03" },
      { img: "img-mainvs04" },
    ]);
    const youtube = ref(false);
    watch(() => youtube.value, (n) => {
      const style = n ? 'hidden' : 'scroll'
      document.documentElement.style.overflow = style
    })
    const close_modal = (v) => {
      console.log("youtube", v);
      youtube.value = v;
    }
    let device_width = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener('resize', () => {
          device_width.value = window.innerWidth;
          fullscreen.value = window.innerHeight;
      })
    })
    const phrase = ref(`에너지 소비가 아닌\n에너지 소유가 되도록!\n기술로 에너지 생태계를\n혁신해 나아갑니다`);
    return {
      main_images,
      youtube,
      phrase,
      fullscreen,
      close_modal,
      device_width,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "swiper/swiper-bundle";

.section-main {
  height: 100vh;
  width: 100%;
}

.main-swiper {
  width: 100%;
  height: 100%;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .swiper-slide {
    width: 100%;
    height: 100vh;
  }
}
</style>
