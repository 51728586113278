<template>
    <section class="section section-recruit">
        <div class="inner-section inner-section-grid">
            <div class="inner-section-title">
                <HeadingTitle title="RECRUIT">
                    <template v-slot:before>
                        <div class="before">
                            <span>H</span>
                        </div>
                    </template>
                </HeadingTitle>
            </div>
            <div class="inner-section-grid-second-div inner-section-recruit">
                <h3>강한 지적능력을 가진 대담하고 창의적인<br>인재를 찾고 있습니다.</h3>
                <div
                    class="recruit-phrase"
                    v-if="device_width > 1024"
                >
                    <p class="">
                        {{ `우수한 인재들을 적극 채용하고 있습니다. 직접 지원한 이력서는 가장 먼저 검토됩니다.` }}
                    </p>
                    <a href="mailto:contact@henergy.xyz">contact@henergy.xyz</a>로 언제든 지원하세요.
                </div>
                <div
                    class="recruit-phrase"
                    v-else
                >
                    <p>
                        {{ `우수한 인재들을 적극 채용하고 있습니다.\n직접 지원한 이력서는 가장 먼저 검토됩니다.` }}
                    </p>
                    <a href="mailto:contact@henergy.xyz">contact@henergy.xyz</a>로 언제든 지원하세요.
                </div>
                <div class="recruit-part-items">
                    <div
                        class="recruit-part-item"
                        v-for="re in recruit"
                        :key="re"
                    >
                        <p class="recruit-label">{{ re.label }}</p>
                        <p class="recruit-content">{{ re.content }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { ref, onMounted, reactive } from "vue";
import HeadingTitle from "../Elements/HeadingTitle.vue";

export default {
  components: {
    HeadingTitle,
  },
  setup() {
    const recruit = reactive([
      { label: "개발자", content: "Frontend, Backend, System Engineer, Data Scientist" },
      { label: "서비스 및 UX", content: "기획 및 콘텐츠 에디팅 실무" },
      { label: "디자이너", content: "웹/앱 ui디자인, 콘텐츠디자인" },
      { label: "마케팅", content: "브랜드 마케팅 기획" },
      { label: "에너지신사업 PM", content: "사업관리, 발주 및 공정관리 및 파트너 관리" },
      { label: "Business Manager", content: "O2O 서비스 (솔라뱅크, 알뜰전기요금제) 서비스 기획 및 관리" },
      { label: "경영지원", content: "경영관리 (인사, 노무, 재무회계 등)"} 
    ]);
    let device_width = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener('resize', () => {
          device_width.value = window.innerWidth;
      })
    })
    return {
      recruit,
      device_width
    };
  },
};
</script>