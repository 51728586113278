<template>
    <div :class="block ? 'heading-title' : 'heading-title heading-title-flex'">
        <slot name="before"></slot>
        <h2>{{ title }}</h2>
        <slot name="after"></slot>
    </div>
    <div class="heading-title multi-line">
        <slot name="multi-title"></slot>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: () => ""
        },
        block: {
            type: Boolean,
            default: () => false
        }
    }
}
</script>