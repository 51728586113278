<template>
    <section class="section service-section">
        <div class="inner-section inner-section-grid">
            <div class="inner-section-title">
                <Heading title="KimTaeYang">
                    <template v-slot:after>
                        <span class="after">
                            SERVICE
                        </span>
                    </template>
                </Heading>
            </div>
            <div v-if="device_width > 1024" class="inner-section-grid-second-div inner-section-img gap">
                <img src="https://cdn.dershare.kr/henergy-web/solar_service_img1.png" alt="" />
                <img src="https://cdn.dershare.kr/henergy-web/solar_service_img2_new.png" alt="" />
            </div>
            <div v-else class="inner-section-grid-second-div inner-section-img gap">
                <p class="img-top-text">再生可能エネルギー<br>エコシステム<br>すべての参加者が<br>ワンストップでサポート<br>されているサービス</p>
                <img src="https://cdn.dershare.kr/henergy-web/m_solar_service_img1.png" alt="" />
                <p class="img-top-text">H Energy<br>再生可能エネルギー共有<br>プラットフォーム<br>ネットワーク</p>
                <p class="img-top-text-sm">ネットワーク効果により、プラットフォームエコシステムの参加者の両方が収益を増やし</p>
                <img src="https://cdn.dershare.kr/henergy-web/m_solar_service_img2.png" alt="" />
            </div>
        </div>
    </section>
</template>

<script>
import Heading from "../../Elements/HeadingTitle.vue"
import { onMounted, ref, reactive } from "vue";

export default {
    components: {
        Heading,
    },
    name: "mohaet-solutions",
    setup() {
        let device_width = ref(window.innerWidth);
        onMounted(() => {
            window.addEventListener('resize', () => {
                device_width.value = window.innerWidth;
            })
        })
        const mo_solutions = reactive([
            {
                title: "市民が分散型小規模発電所に投資、運用するエネルギープラットフォーム",
                content_01: `전기가 없는 우리의 일상, 상상이 가시나요?`,
                content_02: `더 많은 전기가 필요할 우리의 내일을 위해 쓰기만 했던 전기, 요금으로 내기만 했던 전기에서 벗어나 개인이 모여 함께 친환경에너지를 만들고 앞으로의 삶에 깨끗하고 밝은 빛을 밝힐 수 있는 플랫폼입니다.`,
                content_03: `우리를 위해 지구를 위해\n이제 친환경 전기 직접 만들고 건강한 수익까지 함께하는 모햇을 경험해보세요.`,
            }
        ]);
        return {
            mo_solutions,
            device_width
        };
    },
};
</script>
<style lang="scss" scoped>
.gap {
    img:not(:last-child) {
        margin-bottom: 60px;
    }
}

.img-top-text {
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
    // text-align: center;
    margin-bottom: 30px;
}

.img-top-text-sm {
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    // text-align: center;
    margin-bottom: 30px;
}
</style>