<template>
    <section class="section section-contact">
        <div class="inner-section inner-section-grid">
            <div class="inner-section-title">
                <HeadingTitle title="CONTACT">
                    <template v-slot:before>
                        <div class="before">
                            <span>H</span>
                        </div>
                    </template>
                </HeadingTitle>
            </div>
            <div class="inner-section-grid-second-div inner-section-contact">
                <h3>お問い合わせ</h3>
                <div class="contact-phrase">
                    <p>
                        {{ `パートナーシップとソリューションの導入に関するお問い\n合わせは以下にしてください。` }}
                    </p>
                </div>
                <div class="contact-input-form">
                    <div class="input-item">
                        <div class="label-box">
                            <label for="corp_name">法人名</label>
                        </div>
                        <input v-model="form.corp_name" type="text" name="corp_name" placeholder="入力例） 株式会社H Energy">
                    </div>
                    <div class="input-item">
                        <div class="label-box">
                            <label for="name">お名前</label>
                        </div>
                        <input v-model="form.name" type="text" name="name" placeholder="入力例） 山田 太郎">
                    </div>
                    <div class="input-item">
                        <div class="label-box">
                            <label for="mobile">ご連絡先電話番号</label>
                        </div>
                        <input v-model="form.mobile" type="text" name="mobile" placeholder="入力例） 0312345678"
                            @input="valid_mobile">
                    </div>
                    <div class="input-item">
                        <div class="label-box">
                            <label for="email">メールアドレス</label>
                        </div>
                        <div>
                            <input v-model="form.email" type="text" name="email" placeholder="入力例） yamada@example.com"
                                @input="valid_email">
                            <p v-if="email_err" class="input-error">※正しいメールアドレスを入力してください</p>
                        </div>
                    </div>
                    <div class="input-item">
                        <div class="label-box">
                            <label for="email_confirm">メールアドレス</label>
                            <p>※確認用再入力</p>
                        </div>
                        <div>
                            <input v-model="email_confirm" type="text" name="email_confirm"
                                placeholder="入力例） yamada@example.com">
                            <p v-if="email_confirm_err" class="input-error">※メールアドレスが正しいか確認してください</p>
                        </div>
                    </div>
                    <div class="textarea-item">
                        <div class="label-box">
                            <label for="ask">お問い合わせ内容詳細</label>
                            <p>※出来るだけ詳しくお願いします。</p>
                        </div>
                        <textarea type="text" name="ask" v-model="form.email_body" />
                    </div>
                </div>
                <div class="expansion-box">
                    <div class="expansion">
                        <div class="expansion-header" @click="toggle_expansion">
                            <span>（必須）プライバシーポリシー</span>
                            <img src="@/assets/img/arrow_up.png" alt=""
                                :style="open_content ? '' : 'transform: rotate(180deg)'">
                        </div>
                        <div v-if="open_content" class="expansion-content">
                            <p>ご入力いただだきました個人情報につきましては、当社のプライバシーポリシーに基づき適切に管理いたします。詳しくは、「プライバシーポリシー」をご覧いただき、同意の上、お申し込みください。
                            </p>
                            <div class="checkbox-area">
                                <input v-model="agree" type="checkbox" name="agree" checked>
                                <p>プライバシーポリシーに同意する</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="btn-area">
                    <button :class="!show_btn ? '' : 'cursor-pointer'" @click="submit_form"
                        :disabled="!show_btn">入力内容を確認する</button>
                </div>
                <Alert v-if="open_alert" :title="alert_title" :content="alert_content" @close="open_alert = false"></Alert>
            </div>
        </div>
    </section>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import HeadingTitle from "../../Elements/HeadingTitle.vue";
import Alert from "../Modal/Alert.vue"
import { getCurrentInstance } from 'vue'

export default {
    components: {
        HeadingTitle,
        Alert
    },
    setup() {
        const form = ref({
            corp_name: null,
            name: null,
            mobile: null,
            email: null,
            email_body: null,
        })

        // 전송 버튼 활성화
        let email_confirm = ref('');
        let agree = ref(false);

        const show_btn = computed(() => {
            if (!check_input_null()) return false;
            if (!form.value.corp_name) return false;
            if (!form.value.name) return false;
            if (!form.value.mobile) return false;
            if (!form.value.email) return false;
            if (email_err.value) return false;
            if (!email_confirm.value) return false;
            if (email_confirm_err.value) return false;
            if (!form.value.email_body) return false;
            if (!agree.value) return false;
            return true;
        })

        // input 초기화
        const clear_input = () => {
            for (const key in form.value) {
                form.value[key] = null;
            }
            email_confirm.value = null;
            email_err.value = false;
            agree.value = false;
        }

        let device_width = ref(window.innerWidth);

        onMounted(() => {
            window.addEventListener('resize', () => {
                device_width.value = window.innerWidth;
            })
        })

        // input validation
        const check_input_null = function () {
            for (const key in form.value) {
                if (!form.value[key]?.trim()) return false;
            }
            return true;
        }

        const valid_mobile = function () {
            form.value.mobile = form.value.mobile?.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        }

        let email_err = ref(false);

        const valid_email = function (e) {
            const email = e.target.value;
            const pattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
            if (!pattern.test(email)) email_err.value = true;
            else email_err.value = false;
        };

        const email_confirm_err = computed(() => {
            if (!form.value.email) return;
            if (!email_confirm.value) return;
            return form.value.email !== email_confirm.value;
        })

        // 개인정보동의 expansion
        let open_content = ref(true);

        const toggle_expansion = () => {
            open_content.value = !open_content.value
        }

        // form post
        const { proxy } = getCurrentInstance();
        let is_clicked = false;
        let open_alert = ref(false);
        let alert_title = ref('')
        let alert_content = ref('')

        const submit_form = () => {
            // 이중클릭 방지
            if (is_clicked) return;
            is_clicked = true;

            proxy
                .$send_email
                .post(form.value)
                .then(res => {
                    is_clicked = false;
                    console.log(res)
                    open_alert.value = true;
                    alert_title.value = "送信完了"
                    alert_content.value = "担当者が確認してご連絡いたします。"
                    clear_input();
                })
                .catch(err => {
                    is_clicked = false;
                    console.log(err)
                    open_alert.value = true;
                    alert_title.value = "送信失敗"
                    alert_content.value = "不便をお掛けし、申し訳ございません\nしばらくしてからもう一度発送してください。"
                })
        }

        return {
            form,
            submit_form,
            device_width,
            valid_mobile,
            email_err,
            email_confirm_err,
            valid_email,
            check_input_null,
            email_confirm,
            open_content,
            open_alert,
            alert_title,
            alert_content,
            toggle_expansion,
            agree,
            show_btn
        };
    },
};
</script>
<style lang="scss" scoped>
.input-error {
    color: red;
    font-size: 14px;
    padding-top: 4px;
}

.expansion-box {
    padding-top: 30px;
    max-width: 632px;
    margin: 0 auto;
}

.expansion {
    background-color: #FAFAFA;
    padding: 20px 20px 0 20px;
    border: 1px solid #AFAFAF;
    border-radius: 5px;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        padding-bottom: 20px;
    }

    &-content {
        padding: 20px 0;
        border-top: 1px solid #E8E8E8;

        p {
            font-size: 16px;
        }
    }
}

.checkbox-area {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-top: 20px;

    input {
        width: 22px;
        height: 22px;
        accent-color: black;
    }

    p {
        font-size: 16px;
    }
}

.btn-area {
    padding-top: 30px;
    text-align: center;

    button {
        border: 1px solid #C3C3C3;
        border-radius: 50px;
        background: #F7F7F7;
        width: 400px;
        height: 60px;
        font-size: 26px;
    }
}

.cursor-pointer {
    cursor: pointer;
}

@media screen and (max-width: 1025px) {
    .expansion-box {
        padding-top: 20px;
    }

    .expansion {
        &-header {
            font-size: 12px;
            font-weight: 600;
        }

        &-content>p {
            font-size: 12px;
        }
    }

    .content>p {
        font-size: 12px;
    }

    .checkbox-area {
        padding-top: 10px;

        p {
            font-size: 12px;
        }
    }

    .btn-area {
        padding-top: 20px;

        button {
            width: 100%;
            max-width: 335px;
            height: 30px;
            font-size: 16px;
        }
    }
}
</style>