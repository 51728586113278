<template>
    <section class="section service-section">
        <div class="inner-section inner-section-grid">
            <div class="inner-section-title">
                <Heading title="MOHAET">
                    <template v-slot:after>
                        <span class="after">
                            のルーツ
                        </span>
                    </template>
                </Heading>
                <div class="inner-service-logo">
                    <div class="service-img">
                        <img src="https://cdn.dershare.kr/henergy-web/mohaet_service_img2.png" alt=""
                            width="">
                    </div>
                </div>
            </div>
            <div class="inner-section-grid-second-div inner-section-content">
                <div class="service-container">
                    <h3 class="title">MOHAETのルーツは日本の市民発電所</h3>
                    <div class="service-text">
                        <p>市民の出資による発電事業のルーツは、日本にあります。日本では、1990年代から各地で市民が出資し、太陽光発電所や風力発電所を建設、発電事業を行ってきました。とりわけ2000年からは市民風力発電所のような大規模な発電所の開発も行われています。こうした市民の取組が社会を変化させ、日本における再エネの普及の大きな契機となりました。
                        </p>
                        <p>H Energyの創業者であるハムも、創業直前に日本の市民発電所を視察し、その取り組みを学びました。そして韓国において、市民が主役の電気事業を目指したのです。</p>
                        <p>MOHAETは、日本の市民発電所を韓国の電力システムに合わせ、投資プラットフォームと組合方式を導入、設備の可視化システム「KimTaeYang」、電力消費・充放電・取引の最適化ソリューション「DER
                            Share」などの組み合わせも可能なサービスです。</p>
                        <p>MOHAETが目指すのは、市民が再エネ開発の主役となるだけではなく、市民自らが使うエネルギーを自分たちがつくるという、エネルギーの民主主義なのです。</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Heading from "../../Elements/HeadingTitle.vue"
import { reactive } from "vue";

export default {
    components: {
        Heading,
    },
    name: "mohaet-solutions",
    setup() {
        const mo_solutions = reactive([
            {
                title: "市民が分散型小規模発電所に投資、運用するエネルギープラットフォーム",
                content_01: `전기가 없는 우리의 일상, 상상이 가시나요?`,
                content_02: `더 많은 전기가 필요할 우리의 내일을 위해 쓰기만 했던 전기, 요금으로 내기만 했던 전기에서 벗어나 개인이 모여 함께 친환경에너지를 만들고 앞으로의 삶에 깨끗하고 밝은 빛을 밝힐 수 있는 플랫폼입니다.`,
                content_03: `우리를 위해 지구를 위해\n이제 친환경 전기 직접 만들고 건강한 수익까지 함께하는 모햇을 경험해보세요.`,
            }
        ]);
        return {
            mo_solutions,
        };
    },
};
</script>
<style lang="scss" scoped>
.service-img {
    margin-top: 100px;

    img {
        width: 262px;
    }
}

.service-container {
    .title {
        font-weight: 700;
        font-size: 48px;
        line-height: 52px;
        margin-bottom: 30px;
    }

    .service-text {
        p {
            font-size: 20px;
            line-height: 30px;
            color: #555555;

            &:not(:last-child) {
                margin-bottom: 30px;
            }
        }
    }
}

@media screen and (max-width: 1025px) {
    .service-img {
        margin-top: 40px;

        img {
            width: 160px;
        }
    }

    .service-container {
        .title {
            font-weight: 500;
            font-size: 22px;
            line-height: 32px;
        }

        .service-text {
            p {
                font-size: 14px;
                line-height: 19px;
            }
        }
    }
}
</style>