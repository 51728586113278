<template>
  <section class="section section-vision">
    <div class="inner-section inner-section-grid">
      <div class="inner-section-title inner-section-title-nav section-vision-btn">
        <Heading title="VISION">
          <template v-slot:before>
            <div class="before">
              <span>OUR</span>
            </div>
          </template>
        </Heading>
        <div class="btn-wrapper">
          <div class="btn-vision-prev">
            <img src="@/assets/img/btn-arrow.svg" alt="">
          </div>
          <div class="btn-vision-next">
            <img src="@/assets/img/btn-arrow.svg" alt="">
          </div>
        </div>
      </div>
      <swiper class="vision-swiper cursor-pointer"
        :navigation="{ nextEl: '.btn-vision-next', prevEl: '.btn-vision-prev' }"
        :pagination="device_width < 720 ? true : false" :slides-per-view="2.5" :space-between="50" :breakpoints="{
          '0': {
            slidesPerView: 1,
            spaceBetween: 60,
          },
          '720': {
            slidesPerView: 2.5,
            spaceBetween: 40,
            slidesOffsetBefore: 40
          },
        }" @swiper="onSwiper" @slideChange="onSlideChange">
        <swiper-slide v-for="v in visions" :key="v">
          <div class="slide-content">
            <div class="vision-slide">
              <h3 class="">
                {{ v.title }}
              </h3>
              <p v-html="v.content_01">
              </p>
              <p v-html="v.content_02">
              </p>
            </div>
          </div>
        </swiper-slide>
        <template v-slot:container-start>
          <div class="fade-dim" v-if="device_width > 1024">
          </div>
        </template>
      </swiper>
    </div>
    <div class="vision-devider" v-if="device_width > 1024"></div>
  </section>
</template>

<script>
import SwiperCore, { Navigation, Pagination } from "swiper";
import Heading from "../Elements/HeadingTitle.vue"
import { Swiper, SwiperSlide } from "swiper/vue";
import { reactive, ref, onMounted } from "vue";

SwiperCore.use([Navigation, Pagination])

export default {
  components: {
    Heading,
    Swiper,
    SwiperSlide,
  },
  name: "vision",
  setup() {
    const isEnd = ref(false);
    const onSwiper = swiper => {
      console.log(swiper.isEnd);
    };
    const onSlideChange = (swiper) => {
      isEnd.value = swiper.isEnd;
      console.log(swiper.isEnd);
    };

    const visions = reactive([
      {
        title: "에너지,\n소비가 아닌 소유로",
        content_01: "내電내換<br/>개인이 전기를 만들고 소유하고 소비하는 시대를 위해.",
        content_02: `전력을 유튜브처럼<br/>옥상을 에어비엔비처럼 공유하며, 모두가 사업자, 소비자로 에너지를 생산하고 거래할 수 있는 에너지 플랫폼을 운영합니다.`
      },
      {
        title: "에너지 시장을\n혁신하는 리더",
        content_01: `에너지공유플랫폼 안에서 지붕, 옥상 등의 유휴공간을 임대해주는
                  공유자와 재생에너지 투자자가 만나 소규모 태양광발전소를
                  설치하고 새로운 경제 생태계를 이루게 합니다.`,
        content_02: `이를 통해
                  폐쇄적인 전력시장을 혁신하고, 다단계 시장구조를 제거하며
                  누구나 에너지시장에 투자가 가능한 B2C시장으로 만들어갑니다.`
      },
      {
        title: "에너지의 최적화\n밸런싱 기술",
        content_01: `AI, Data Science, Cloud-Edge 기술을 통해 ESS를 최적운영하고,
                  태양광, ESS 등 분산자원을 실시간 밸런싱 기술을 통해
                  전력중개시장에서의 수익을 극대화 합니다.`,
        content_02: `분산자원을 VPP로
                  결합하여 수급관리, 조정력시장에 참여하여 수익을 창출하고
                  있습니다.`
      },
    ]);
    let device_width = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener('resize', () => {
        device_width.value = window.innerWidth;
      })
    })
    return {
      visions,
      isEnd,
      device_width,
      onSwiper,
      onSlideChange,
    };
  },
};
</script>
<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
