<template>
    <section class="section service-section">
        <div class="inner-section inner-section-grid">
            <div class="inner-section-title">
                <Heading title="MOHAET">
                    <template v-slot:after>
                        <span class="after">
                            PLATFORM
                        </span>
                    </template>
                </Heading>
                <div class="inner-service-logo">
                    <div class="service-logo">
                        <img src="@/assets/img/img-renewal-logo.png" alt="">
                    </div>
                </div>
            </div>
            <div class="inner-section-grid-second-div inner-section-content">
                <div class="solution" v-for="mo in mo_solutions" :key="mo">
                    <Solution :title="mo.title" :eng="mo.eng" :subtitle="mo.subtitle" :mobile_subtitle="mo.mobile_subtitle"
                        :content_01="mo.content_01" :content_02="mo.content_02" :content_03="mo.content_03" />
                    <div v-if="mo.title === '모햇'" class="flex">
                        <ServiceButton label="모햇 바로가기" link="https://mohaet.com/">
                            <template v-slot:icon>
                                <img src="@/assets/img/arrow_right.svg" alt="" class="service">
                            </template>
                        </ServiceButton>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Heading from "../Elements/HeadingTitle.vue"
import ServiceButton from "../Elements/ServiceButton.vue"
import Solution from "../Elements/Solution.vue"
import { reactive } from "vue";

export default {
    components: {
        Heading,
        ServiceButton,
        Solution
    },
    name: "mohaet-solutions",
    setup() {
        const mo_solutions = reactive([
            {
                title: "모햇",
                subtitle: `친환경 전기투자 플랫폼,`,
                content_01: `전기가 없는 우리의 일상, 상상이 가시나요?`,
                content_02: `더 많은 전기가 필요할 우리의 내일을 위해 쓰기만 했던 전기, 요금으로 내기만 했던 전기에서 벗어나 개인이 모여 함께 친환경에너지를 만들고 앞으로의 삶에 깨끗하고 밝은 빛을 밝힐 수 있는 플랫폼입니다.`,
                content_03: `우리를 위해 지구를 위해\n이제 친환경 전기 직접 만들고 건강한 수익까지 함께하는 모햇을 경험해보세요.`,
            },
            {
                title: "모햇옥상발전소",
                subtitle: `세상에서 가장 작지만 힘이 센`,
                content_01: `모햇발전소는 나무와 풀, 작은 생명들의 터전인 자연을 훼손하고 만드는 것이 아닌, 이미 지어진 건물의 빈 옥상과 지붕에 만들어져 <span style="font-weight: 700;">자연훼손 없는 진짜 친환경 에너지</span>를 만듭니다.`,
                content_02: `누군가에게는 그저 빈 공간이기만 한 옥상이 모햇에서는  친환경 에너지를 만들 터전이 되고 <span style="font-weight: 700;">탄소중립</span>을 일궈내는 가치있는 공간입니다.`
            },

        ]);
        return {
            mo_solutions,
        };
    },
};
</script>
<style lang="scss" scoped>
.inner-section {
    padding: 120px 0 112px;

    @media screen and (max-width: 1025px) {
        padding: 50px 20px 30px;
    }
}
</style>