<template>
    <section class="section service-section">
        <div class="inner-section inner-section-grid">
            <div class="inner-section-title">
                <Heading title="솔라뱅크">
                    <template v-slot:after>
                        <span class="after"> SERVICE </span>
                    </template>
                </Heading>
                <div class="inner-service-logo">
                    <div class="service-logo">
                        <img
                            src="@/assets/img/img-solarbank_logo.png"
                            alt=""
                        >
                    </div>
                </div>
            </div>
            <div class="inner-section-grid-second-div inner-section-content">
                <div
                    class="solution"
                    v-for="der in kim_solutions"
                    :key="der"
                >
                    <Solution
                        :title="der.title"
                        :eng="der.eng"
                        :subtitle="der.subtitle"
                        :mobile_subtitle="der.mobile_subtitle"
                        :content_01="der.content_01"
                        :content_02="der.content_02"
                        :content_03="der.content_03"
                    />
                    <div
                        v-if="der.title === '솔라뱅크'"
                        class="flex"
                    >
                        <ServiceButton
                            label="솔라뱅크 홈페이지 바로가기"
                            width="300px"
                            link="https://solarbank.kr/"
                        >
                            <template v-slot:icon>
                                <img
                                    src="@/assets/img/arrow_right.svg"
                                    alt=""
                                    class="service"
                                >
                            </template>
                        </ServiceButton>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Heading from "../Elements/HeadingTitle.vue";
import ServiceButton from "../Elements/ServiceButton.vue"
import Solution from "../Elements/Solution.vue";
import { reactive } from "vue";

export default {
  components: {
    Heading,
    ServiceButton,
    Solution,
  },
  name: "dershare-solutions",
    setup () {
        console.log(window.innerWidth);
        const kim_solutions = reactive([
        {
            title: "솔라뱅크",
            subtitle: `태양광 발전소 원스톱 플랫폼, `,
            mobile_subtitle: "함께 만들어가는<br/>발전소 관리의 새로운 기준,",
            content_01: `재생에너지의 가치를 가장 크게 끌어낼 수 있는 태양광 발전소,<br/>솔라뱅크는 이 시장에 누구나 쉽고 빠르게 참여할 수 있도록 돕습니다.`,
            content_02: `땅이나 지붕(옥상)이 있는 사람은 발전소를 지어 발전 수익을 직접 창출하거나, 발전소 부지로 임대해 임대 수익을 창출할 수 있고, 이미 발전소를 운영하고 있는 사람이라면 솔라뱅크가 대신 발전소를 운영하고 관리해 효율을 극대화하여 수익성을 개선할 수 있습니다.`,
            content_03: `그동안의 태양광 시장이 가진 불편함을 없애고, 장점만을 살릴 수 있는 이 모든 서비스는 솔라뱅크의 발전소 운영 관리 기술을 통해 이뤄지고 있습니다. 지금 바로 솔라뱅크에 합류해 보세요.`,
        },
        ]);
        return {
        kim_solutions,
        };
    },
    
  
};
</script>
