<template>
  <section class="section section-staff">
    <div class="inner-section">
      <div class="inner-section-title inner-section-title-nav section-staff-btn">
        <HeadingTitle title="STAFF">
          <template v-slot:before>
            <div class="before">
              <span>H</span>
            </div>
          </template>
        </HeadingTitle>
        <div class="btn-wrapper">
          <div class="btn-staff-prev">
            <img src="@/assets/img/btn-arrow.svg" alt="">
          </div>
          <div class="btn-staff-next">
            <img src="@/assets/img/btn-arrow.svg" alt="">
          </div>
        </div>
      </div>
      <div class="inner-section-staff">
        <div class="swiper-container">
          <swiper class="staff-swiper" :navigation="{ nextEl: '.btn-staff-next', prevEl: '.btn-staff-prev' }"
            :pagination="device_width < 720" :slides-per-view="4" :space-between="0" :breakpoints="{
            '0': {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            '720': {
              slidesPerView: 4,
              spaceBetween: 0,
            },
          }" @swiper="onSwiper" @slideChange="onSlideChange">
            <swiper-slide v-for="s in staffs" :key="s">
              <div class="people">
                <div class="people-img">
                  <img :src="s.img" alt="" />
                </div>
                <div class="people-info">
                  <p class="people-name text-bold">{{ s.name }}</p>
                  <p class="people-position">{{ s.position }}</p>
                  <p class="people-description">{{ s.description }}</p>
                </div>
              </div>
            </swiper-slide>
            <template v-slot:container-start>
              <div v-if="device_width > 1024" class="fade-dim-left">
              </div>
              <div v-if="device_width > 1024" class="fade-dim-right">
              </div>
            </template>
            <template v-slot:container-end>
            </template>
          </swiper>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, reactive } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import HeadingTitle from "../Elements/HeadingTitle.vue";

export default {
  components: {
    Swiper,
    HeadingTitle,
    SwiperSlide,
  },
  setup() {
    const onSwiper = swiper => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    const staffs = reactive([
      {
        name: "함일한",
        img: "https://cdn.dershare.kr/henergy-web/ceo.jpg",
        position: "CEO & founder",
        description: "CMO, Encored Technologies\n에너지사업팀장, LG CNS\n컴퓨터비젼 / 수학석사, POSTECH",
      },
      {
        name: "임성빈",
        img: "https://cdn.dershare.kr/henergy-web/cto.jpg",
        position: "CTO",
        description: "연구소장, 미래엔에스\n개발팀장, 지노시스템\n확률/수학 석사, POSTECH",
      },
      {
        name: "조욱희",
        img: "https://cdn.dershare.kr/henergy-web/cpo.jpg",
        position: "CPO",
        description: "기술이사, knix\n기술이사, Aromasoft(상장)\n컴퓨터공학, POSTECH",
      },
      {
        name: "민경록",
        img: "https://cdn.dershare.kr/henergy-web/cfo.jpg",
        position: "CFO",
        description: "영업/심사 이사, OK금융그룹\n신용관리, 삼성카드\n산업공학 석사, POSTECH",
      },
      {
        name: "최희근",
        img: "https://cdn.dershare.kr/henergy-web/cmo2.jpg",
        position: "CMO",
        description: "부법인장, OK FIN\n(P2P, 베트남)\n마케팅기획부장, OK금융그룹\n영업기획부장, OK금융그룹\n영업지부장, OK금융그룹\n경기과학기술대",
      },
      {
        name: "조영탁",
        img: "https://cdn.dershare.kr/henergy-web/cso3.jpg",
        position: "CSO",
        description: "개발총괄전무, Blackwood\nIPP 그룹장, 한화임팩트\n태양광 파트장, LG CNS\n정보통신공학 석사, 아주대",
      },
      {
        name: "채병기",
        img: "https://cdn.dershare.kr/henergy-web/po.jpg",
        position: "CPO",
        description: "UX Designer,\nLG전자 디자인경영센터\n인간공학 석사, POSTECH",
      },
      {
        name: "SEA Lab POSTECH",
        img: "https://cdn.dershare.kr/henergy-web/sealab.jpg",
        position: "",
        description: "최동구 교수, POSTECH\nSystem and Economic\nDecision Analytics Lab\n전력중계 최적입찰량 결정 및\nAI 학습모델",
      },
    ]);
    let device_width = ref(window.innerWidth);
    window.addEventListener('resize', () => {
      device_width.value = window.innerWidth; // 현재 스크롤바 위치
    })
    return {
      staffs,
      device_width,
      onSwiper,
      onSlideChange,
    };
  },
};
</script>
