<template>
    <header>
        <div class="header-container flex space-between"
            :class="scroll_location > 0 || $route.path === '/notice' ? '' : 'position-top'" v-if="device_width > 1024">
            <div class="logo" @click="goto_home">
                <img src="https://cdn.dershare.kr/henergy-web/h_logo.svg" alt="">
            </div>
            <nav>
                <ul class="main-nav">
                    <li v-for="nav in nav_list" :key="nav">
                        <router-link :to="{ path: '/', hash: nav.hash }">{{ nav.label }}</router-link>
                    </li>
                </ul>
            </nav>
            <nav>
                <ul class="sns">
                    <li>
                        <a href="https://mohaet.com/" target="_blank">
                            <img v-if="scroll_location > 0" style="height: 16px;"
                                src="https://cdn.dershare.kr/henergy-web/img-mohaet-gray.png">
                            <img v-else style="height: 16px;"
                                src="https://cdn.dershare.kr/henergy-web/img-mohaet-white.png">
                        </a>
                    </li>
                    <li>
                        <a href="https://solarbank.kr/" target="_blank">
                            <img v-if="scroll_location > 0" style="height: 24px;"
                                src="@/assets/img/img-solarbank-gray.svg">
                            <img v-else style="height: 24px;"
                                src="@/assets/img/img-solarbank-white.svg">
                        </a>
                    </li>
                    <li>
                        <div class="split"></div>
                    </li>
                    <li>
                        <a href="https://blog.naver.com/h__energy" target="_blank">
                            <img v-if="scroll_location > 0"
                                src="https://cdn.dershare.kr/henergy-web/img-naver_blog-gray.png">
                            <img v-else src="https://cdn.dershare.kr/henergy-web/img-naver_blog-white.png">
                        </a>
                    </li>
                    <li>
                        <a href="https://www.facebook.com/mohaet.official/" target="_blank">
                            <img v-if="scroll_location > 0"
                                src="https://cdn.dershare.kr/henergy-web/img-facebook-gray.png">
                            <img v-else src="https://cdn.dershare.kr/henergy-web/img-facebook-white.png">
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/do_the_mohaet/" target="_blank">
                            <img v-if="scroll_location > 0"
                                src="https://cdn.dershare.kr/henergy-web/img-instargram-gray.png">
                            <img v-else src="https://cdn.dershare.kr/henergy-web/img-instargram-white.png">
                        </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCTID047Mesr5A6cd2PzV9Wg" target="_blank">
                            <img v-if="scroll_location > 0"
                                src="https://cdn.dershare.kr/henergy-web/img-youtube-gray.png">
                            <img v-else src="https://cdn.dershare.kr/henergy-web/img-youtube-white.png">
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
        <div v-else>
            <div class="mobile-header" :class="scroll_location > 0 || $route.path === '/notice' ? '' : 'position-top'">
                <div class="mobile-menu">
                    <div class="logo" @click="goto_home">
                        <img src="https://cdn.dershare.kr/henergy-web/h_logo.svg" alt="">
                    </div>
                    <div class="menu_btn"><img src="@/assets/img/menu.svg" alt="햄버거메뉴" @click="mobile_side = true" /></div>
                </div>
            </div>
            <div class="mobile-side" v-show="mobile_side">
                <div class="mobile-side-container">
                    <div class="mobile-side-wrap">
                        <div class="mobile-side-header">
                            <div class="mobile-menu">
                                <div class="logo inner-logo" @click="goto_home">
                                    <img src="https://cdn.dershare.kr/henergy-web/h_logo.svg" alt="">
                                </div>
                                <div class="close_btn">
                                    <img src="@/assets/img/close.svg" alt="닫기버튼" @click="mobile_side = false" />
                                </div>
                            </div>
                        </div>
                        <div class="mobile-side-menus">
                            <div class="mobile-nav">
                                <nav>
                                    <ul>
                                        <li v-for="nav in nav_list" :key="nav">
                                            <router-link :to="nav.hash" @click="mobile_side = false">{{ nav.label
                                            }}</router-link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mobile-dim" @click="mobile_side = false"></div>
            </div>
        </div>
    </header>
</template>
<script>
import { ref, reactive, onMounted, watch } from "vue";
import { sns, bizs } from "./nav";
export default {
    components: {
    },
    setup() {
        let mobile_side = ref(false);
        let scroll_location = ref(0);
        const nav_list = reactive([
            { label: "Our vision", hash: "#our_vision" },
            { label: "Service", hash: "#service" },
            { label: "Solutions", hash: "#solutions" },
            { label: "Staff", hash: "#staff" },
            { label: "History", hash: "#history" },
            { label: "Recruit", hash: "#recruit" },
        ]);
        const sns_list = reactive(sns);
        const biz_list = reactive(bizs);
        const goto_home = () => {
            window.location.href = "/"
        }
        const contact_list = reactive([
            {
                title: "사업관련 문의",
                list: [
                    { label: "02-542-0327", icon: "call.png", link: "tel:02-542-0327" },
                ],
            },
            {
                title: "협력사 모집공고",
                list: [
                    {
                        label: "모집공고 바로가기",
                        icon: "arrow.png",
                        link: `${window.location.origin}/notice`,
                    },
                ],
            },
        ]);
        const lock_scroll = e => e.preventDefault();

        watch(() => mobile_side.value, (n) => {
            if (n) {
                document.documentElement.addEventListener('touchmove', lock_scroll, { passive: false });
                document.documentElement.style.overflow = 'hidden'
            } else {
                document.documentElement.removeEventListener('touchmove', lock_scroll, { passive: false });
                document.documentElement.style.overflow = 'scroll'
            }
        })

        window.addEventListener('scroll', () => {
            scroll_location.value = document.documentElement.scrollTop; // 현재 스크롤바 위치
        })

        let device_width = ref(window.innerWidth);
        onMounted(() => {
            window.addEventListener('resize', () => {
                device_width.value = window.innerWidth;
            })
        })

        return {
            goto_home,
            mobile_side,
            device_width,
            lock_scroll,
            nav_list,
            sns_list,
            biz_list,
            contact_list,
            scroll_location
        }
    },
}
</script>