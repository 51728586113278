<template>
    <section class="section service-section">
        <div class="inner-section inner-section-grid">
            <div class="inner-section-title">
                <Heading title="MOHAET">
                    <template v-slot:after>
                        <span class="after">
                            SERVICE
                        </span>
                    </template>
                </Heading>
                <div class="inner-service-logo">
                    <div class="service-logo">
                        <img src="@/assets/img/jp/mohaet_only_logo.png" alt="logo">
                    </div>
                </div>
            </div>
            <div class="inner-section-grid-second-div inner-section-content">
                <div class="service-container">
                    <h3 class="title">市民が分散型小規模発電所に投資、運用するエネルギープラットフォーム</h3>
                    <div class="service-text">
                        <p class="subtitle">月々固定の利回りによる太陽光発電所投資</p>
                        <div class="content">
                            <span>-</span>
                            <p> 市民が投資しやすいシステムにより、投資規模は拡大中</p>
                        </div>
                        <div class="content">
                            <span>-</span>
                            <p>太陽光発電に関する専門的な知識がなくても、安心して投資できるプラットフォーム</p>
                        </div>
                        <div class="content">
                            <span>-</span>
                            <p>少額からの投資が可能で、組合への市民投資家は増加中</p>
                        </div>
                    </div>
                    <div class="service-text">
                        <p class="subtitle">太陽光発電所の開発にあたって、バリューチェーンのデータを分析、リスク管理を通じて、固定の利回りを実現</p>
                        <div class="content">
                            <span>-</span>
                            <p>発電所の設備利用率や建設・運営コスト、自然災害による損害の確率も反映させた、詳細なデータ分析に基づくリスク管理</p>
                        </div>
                        <div class="content">
                            <span>-</span>
                            <p>投資の翌月から利子の配当を実現した、発電予測に基づく収益管理</p>
                        </div>
                    </div>
                    <p class="add-text">＊韓国のMOHAETサービスの概要です。日本市場向けサービスは、既存のシステムをベースに、企画を進めています。</p>
                    <div class="flex-container">
                        <img src="https://cdn.dershare.kr/henergy-web/mohaet_service_img1.png" alt=""
                            width="206px">
                        <div>
                            <div class="rounds">
                                <div class="round left">参加世帯数</div>
                                <div class="round right">26,122世帯</div>
                            </div>
                            <div class="rounds">
                                <div class="round left">累積投資額</div>
                                <div class="round right">497.57億ウォン(約53.3億円）</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Heading from "../../Elements/HeadingTitle.vue"
import { reactive } from "vue";

export default {
    components: {
        Heading,
    },
    name: "mohaet-solutions",
    setup() {
        const mo_solutions = reactive([
            {
                title: "市民が分散型小規模発電所に投資、運用するエネルギープラットフォーム",
                content_01: `전기가 없는 우리의 일상, 상상이 가시나요?`,
                content_02: `더 많은 전기가 필요할 우리의 내일을 위해 쓰기만 했던 전기, 요금으로 내기만 했던 전기에서 벗어나 개인이 모여 함께 친환경에너지를 만들고 앞으로의 삶에 깨끗하고 밝은 빛을 밝힐 수 있는 플랫폼입니다.`,
                content_03: `우리를 위해 지구를 위해\n이제 친환경 전기 직접 만들고 건강한 수익까지 함께하는 모햇을 경험해보세요.`,
            }
        ]);
        return {
            mo_solutions,
        };
    },
};
</script>
<style lang="scss" scoped>
.service-container {
    .title {
        font-weight: 700;
        font-size: 48px;
        line-height: 52px;
        margin-bottom: 30px;
    }

    .subtitle {
        margin-bottom: 10px;
        font-weight: 600;
    }

    .service-text {
        margin-bottom: 30px;

        .content {
            display: flex;
            align-items: baseline;
            gap: 4px;
        }

        p {
            font-size: 20px;
            line-height: 30px;
        }
    }

    .add-text {
        font-size: 18px;
        font-weight: 300;
        line-height: 28px;
        color: #555555;
    }

    .flex-container {
        margin-top: 30px;
        display: flex;
        gap: 20px;
        align-items: flex-end;
    }

    .rounds {
        display: flex;

        &:last-child {
            margin-top: 20px;
        }

        .round {
            font-size: 20px;
            border: 3px solid #000000;
            padding: 10px 20px;
            text-align: center;
        }

        .left {
            background: #000000;
            border-radius: 50px 0 0 50px;
            color: white;
            font-weight: 600;
            width: 200px;
        }

        .right {
            background: #FFFFFF;
            border-radius: 0px 50px 50px 0px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    @media screen and (max-width: 1025px) {
        .title {
            font-weight: 500;
            font-size: 22px;
            line-height: 32px;
        }

        .subtitle {
            font-size: 16px;
            font-weight: 700;
        }

        .service-text {
            p {
                font-size: 14px;
                line-height: 19px;
            }
        }

        .add-text {
            font-size: 14px;
            line-height: 19px;
        }

        .flex-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: flex-start;
        }

        .rounds>.round {
            font-size: 14px;
        }
    }
}
</style>