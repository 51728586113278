<template>
  <div class="solution-container">
    <div class="solution-title">
      <h3 class="solution-subtitle" v-if="is_mobile && mobile_subtitle" v-html="mobile_subtitle"></h3>
      <h3 class="solution-subtitle" v-else>{{ subtitle }}</h3>
      <h3>{{ title }}
        <span>{{ eng }}</span>
      </h3>

    </div>
    <div class="solution-content">
      <p v-if="content_01" v-html="content_01"></p>
      <p v-if="content_02" v-html="content_02"></p>
      <p v-if="content_03" v-html="content_03"></p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "solution",
  props: {
    title: {
      type: String,
      default: () => ""
    },
    eng: {
      type: String,
      default: () => ""
    },
    subtitle: {
      type: String,
      default: () => ""
    },
    mobile_subtitle: {
      type: String,
      default: () => ""
    },
    content_01: {
      type: String,
      default: () => ""
    },
    content_02: {
      type: String,
      default: () => ""
    },
    content_03: {
      type: String,
      default: () => ""
    },
  },
  setup() {
    const is_mobile = ref(0)
    window.addEventListener('resize', function () {
      is_mobile.value = window.innerWidth <= 450;
    })

    return {
      is_mobile
    };
  }
};
</script>