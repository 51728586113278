<template>
  <section class="section image-section">
    <div class="inner-section inner-section-grid">
      <div class="inner-section-title">
        <HeadingTitle :title="title" :block="true">
          <template v-slot:before>
            <p class="header-grey">
              {{ grey_title }}
            </p>
          </template>
          <template v-if="multi_title" v-slot:multi-title>
            <h2>{{ multi_title }}</h2>
          </template>
        </HeadingTitle>
        <div class="heading-content" v-if="heading_content">
          <p>市民などの投資家の資金によって、組合を設立。 </p>
          <p>組合が再エネ発電所を所有</p>
          <p>組合の運営および発電所の案件開発と運営・管理はH Energyが担当</p>
          <p>原則として再エネ発電所の施工や運転管理は地域の施工会社</p>
          <p>運転管理会社の協力による</p>
        </div>
      </div>
      <div class="inner-section-grid-second-div inner-section-img gap" v-if="index_array.length">
        <div v-for="(index, i) in index_array" :key="`img${i}`">
          <img v-if="device_width > 1024"
            :src="`https://cdn.dershare.kr/henergy-web/img-main-emf${index}.png`" alt="" />
          <img v-else :src="`https://cdn.dershare.kr/henergy-web/m_img-main-emf${index}.png`" alt="" />
        </div>
      </div>
      <div class="inner-section-grid-second-div inner-section-img" v-else>
        <img v-if="device_width > 1024"
          :src="`https://cdn.dershare.kr/henergy-web/img-main-emf${index}.png`" alt="" />
        <img v-else :src="`https://cdn.dershare.kr/henergy-web/m_img-main-emf${index}.png`" alt="" />
      </div>
    </div>
  </section>
</template>

<script>
import { onMounted, ref } from "vue";
import HeadingTitle from "../../Elements/HeadingTitle.vue";
export default {
  name: "img-flow",
  components: {
    HeadingTitle,
  },
  props: {
    grey_title: {
      type: String,
      default: () => "ELECTRICITY"
    },
    title: {
      type: String,
      default: () => ""
    },
    multi_title: {
      type: String,
      default: () => ""
    },
    heading_content: {
      type: Boolean,
      default: () => false
    },
    index: {
      type: Number,
      default: () => 1
    },
    index_array: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    let device_width = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener('resize', () => {
        device_width.value = window.innerWidth;
      })
    })
    return {
      device_width,
    };
  },
};
</script>
<style lang="scss">
.inner-section-title {
  padding-bottom: 24px;
}

.heading-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 40px;
  padding-left: 40px;
  text-align: left;
  width: 370px;

  p {
    font-size: 20px;
    line-height: 30px;
    color: #555555;
  }

  @media screen and (max-width: 1025px) {
    display: block;
    margin-top: 30px;
    padding-left: 0;
    width: 100%;

    p {
      font-size: 16px;
      line-height: 22px;
      color: #555555;
      padding-bottom: 5px;
    }
  }
}

.gap {
  div:not(:last-child) {
    margin-bottom: 30px;
  }
}
</style>
