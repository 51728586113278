<template>
    <div class="modal">
        <div class="modal-container">
            <div class="modal-wrap">
                <div class="modal-header">
                    <p>{{ title }}</p>
                    <div class="close_btn">
                        <img src="@/assets/img/close.svg" alt="닫기버튼" @click="$emit('close')" />
                    </div>
                </div>
                <div class="modal-body">
                    <p>{{ content }}</p>
                </div>
                <div class="modal-btn">
                    <button @click="$emit('close')">確認</button>
                </div>
            </div>
        </div>
        <div class="modal-dim" @click="$emit('close')"></div>
    </div>
</template>
<script>
import { ref } from "@vue/reactivity";

export default {
    props: {
        title: {
            type: String,
            default: () => ""
        },
        content: {
            type: String,
            default: () => ""
        },
    },
    setup() {
        let device_width = ref(window.innerWidth);
        window.addEventListener('resize', () => {
            device_width.value = window.innerWidth;
        })

        return {
            device_width
        };
    },
};
</script>
<style lang="scss" scoped>
.modal-header {
    display: grid;
    grid-template-columns: 1fr 30px;
    align-items: center;
    z-index: 99;
    padding: 20px;
    text-align: center;

    p {
        font-size: 24px;
        font-weight: 600;
        padding-left: 32px;
    }
}

.modal {
    z-index: 999;
    position: fixed;
}

.modal-container {
    width: 610px;
    background: #FFFFFF;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    border-radius: 16px;
}

.modal-wrap {
    display: flex;
    flex-direction: column;
}

.modal-body {
    text-align: center;
    padding: 0 50px 40px;

    p {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
    }
}

.modal-btn {
    padding: 0 50px 20px;
    text-align: center;

    button {
        background-color: #000000;
        color: #fff;
        font-size: 26px;
        font-weight: 500;
        border-radius: 50px;
        border: 0;
        width: 100%;
        max-width: 240px;
        height: 60px;
        cursor: pointer;
    }
}

.modal-dim {
    background-color: #0000009e;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 98;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
        /* Chrome, Safari, Opera*/
        background: transparent;
        /* Optional: just make scrollbar invisible */
        -webkit-appearance: none;
    }
}

.close_btn {
    cursor: pointer;
}

@media screen and (max-width: 1025px) {
    .modal-container {
        width: 90%;
        max-width: 610px;
    }

    .modal-btn {
        button {
            font-size: 16px;
            height: 30px;
        }
    }
}
</style>
