<template>
  <div class="solution-container">
    <div class="solution-title">
      <h3>{{ title }}</h3>
      <h3 v-if="subtitle" class="solution-subtitle jp">{{ subtitle }}</h3>
    </div>
    <p v-if="content_title" class="content-title">{{ content_title }}</p>
    <div class="solution-content">
      <div class="content-line">
        <span>-</span>
        <p v-html="content_01"></p>
      </div>
      <div class="content-line">
        <span>-</span>
        <p v-html="content_02"></p>
      </div>
      <div class="content-line">
        <span>-</span>
        <p v-html="content_03"></p>
      </div>
      <div class="content-line">
        <span>-</span>
        <p v-html="content_04"></p>
      </div>
      <div class="content-line">
        <span>-</span>
        <p v-html="content_05"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "solution",
  props: {
    title: {
      type: String,
      default: () => ""
    },
    eng: {
      type: String,
      default: () => ""
    },
    subtitle: {
      type: String,
      default: () => ""
    },
    content_title: {
      type: String,
      default: () => ""
    },
    mobile_subtitle: {
      type: String,
      default: () => ""
    },
    content_01: {
      type: String,
      default: () => ""
    },
    content_02: {
      type: String,
      default: () => ""
    },
    content_03: {
      type: String,
      default: () => ""
    },
    content_04: {
      type: String,
      default: () => ""
    },
    content_05: {
      type: String,
      default: () => ""
    },
  },
  setup() {
    const is_mobile = ref(0)
    window.addEventListener('resize', function () {
      is_mobile.value = window.innerWidth <= 450;
    })

    return {
      is_mobile
    };
  }
};
</script>
<style lang="scss" scoped>
.solution-title>h3:first-child {
  padding-bottom: 20px;
}

.solution-content {
  padding-top: 10px !important;

  .content-line {
    display: flex;
    align-items: baseline;
    gap: 4px;
  }

  p {
    word-break: normal !important;
    line-height: 30px !important;
  }
}

.content-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #555555;
  padding-top: 30px;
}

@media screen and (max-width: 1025px) {
  .solution-title>h3 {
    font-size: 22px !important;
    font-weight: 500 !important;

    &:first-child {
      padding-bottom: 0;
    }
  }

  .solution-content {
    p {
      line-height: 19px !important;
    }
  }

  .content-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
  }
}
</style>