<template>
  <section class="section section-staff">
    <div class="inner-section">
      <div class="inner-section-title inner-section-title-nav section-staff-btn">
        <HeadingTitle title="PEOPLE">
          <template v-slot:before>
            <div class="before">
              <span>H</span>
            </div>
          </template>
        </HeadingTitle>
        <div v-if="device_width < 720" class="btn-wrapper">
          <div class="btn-staff-prev">
            <img src="@/assets/img/btn-arrow.svg" alt="">
          </div>
          <div class="btn-staff-next">
            <img src="@/assets/img/btn-arrow.svg" alt="">
          </div>
        </div>
      </div>
      <div class="inner-section-staff">
        <div class="swiper-container">
          <swiper class="staff-swiper" :navigation="{ nextEl: '.btn-staff-next', prevEl: '.btn-staff-prev' }"
            :pagination="device_width < 720" :slides-per-view="4" :space-between="0" :breakpoints="{
              '0': {
                slidesPerView: 2,
                spaceBetween: 0,
              },
              '720': {
                slidesPerView: 4,
                spaceBetween: 0,
              },
            }" @swiper="onSwiper" @slideChange="onSlideChange">
            <swiper-slide v-for="s in staffs" :key="s">
              <div class="people">
                <div class="people-img">
                  <img :src="s.img" alt="" />
                </div>
                <div class="people-info">
                  <p class="people-name">{{ s.name }}</p>
                  <p class="people-position">{{ s.position }}</p>
                  <p v-if="device_width > 720" class="people-description">{{ s.description }}</p>
                  <p v-else class="people-description">{{ s.mobile_description }}</p>
                </div>
              </div>
            </swiper-slide>
            <template v-slot:container-start>
              <div v-if="device_width > 1024" class="fade-dim-left">
              </div>
              <div v-if="device_width > 1024" class="fade-dim-right">
              </div>
            </template>
            <template v-slot:container-end>
            </template>
          </swiper>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, reactive } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import HeadingTitle from "../../Elements/HeadingTitle.vue";

export default {
  components: {
    Swiper,
    HeadingTitle,
    SwiperSlide,
  },
  setup() {
    const onSwiper = swiper => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    const staffs = reactive([
      {
        name: "ハム・イルホン",
        img: "https://cdn.dershare.kr/henergy-web/ceo.jpg",
        position: "代表取締役・創業者",
        description: `浦項工科大学校数学科学士、\n修士取得、\nLG CNS コンピュータビジョン、\nEncored Technologies エナジー事業\nチーム長を経て、\n現職`,
        mobile_description: `浦項工科大学校数学科学士、\n修士取得\nLG CNS コンピュータビ\nジョン、\nEncored Technologies エナジー事業\nチーム長を経て、\n現職`,
      },
      {
        name: "本橋恵一",
        img: "https://cdn.dershare.kr/henergy-web/jp_manager.png",
        position: "日本担当カントリーマネー\nジャー",
        description: "環境エネルギージャーナリスト\nENCORED Japan マーケティング本\n部長\nEnergy Shift 編集マネージャー\nエナシフTV　キャスター",
        mobile_description: "環境エネルギージャーナリ\nスト\nENCORED Japan マー\nケティング本部長\nEnergy Shift 編集マネー\nジャー\nエナシフTV　キャスター",
      },
      {
        name: "イム・ソンビン",
        img: "https://cdn.dershare.kr/henergy-web/cto.jpg",
        position: "CTO",
        description: "研究所長、\n未来エンエス開発チーム長、\nジノシステム、\n数学修士(確率)、\nPOSTECH",
        mobile_description: "研究所長、\n未来エンエス\n開発チーム長、\nジノシステム、\n数学修士(確率)、\nPOSTECH",
      },
      {
        name: "ミンギョンロック",
        img: "https://cdn.dershare.kr/henergy-web/cfo.jpg",
        position: "CFO",
        description: "営業/審査理事、\nOK金融クレジット管理、\nSamsungカード\n産業工学のマスター、\nPOSTECH",
        mobile_description: "営業/審査理事、\nOK金融\nクレジット管理、\nSamsungカード\n産業工学のマスター、\nPOSTECH",
      },
    ]);
    let device_width = ref(window.innerWidth);
    window.addEventListener('resize', () => {
      device_width.value = window.innerWidth; // 현재 스크롤바 위치
    })
    return {
      staffs,
      device_width,
      onSwiper,
      onSlideChange,
    };
  },
};
</script>
<style lang="scss" scoped>
.people {
  width: 340px;

  @media screen and (max-width: 720px) {
    width: 200px;
  }
}
</style>