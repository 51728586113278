<template>
    <section class="section service-section">
        <div class="inner-section inner-section-grid">
            <div class="inner-section-title">
                <Heading title="DERShare">
                    <template v-slot:after>
                        <span class="after"> SOLUTIONS </span>
                    </template>
                </Heading>
                <div class="inner-service-logo">
                    <div class="service-logo">
                        <img
                            src="@/assets/img/img-solution-logo_der.png"
                            alt=""
                        >
                    </div>
                </div>
            </div>
            <div class="inner-section-grid-second-div inner-section-content">
                <div
                    class="solution"
                    v-for="der in der_solutions"
                    :key="der"
                >
                    <Solution
                        :title="der.title"
                        :eng="der.eng"
                        :subtitle="der.subtitle"
                        :mobile_subtitle="der.mobile_subtitle"
                        :content_01="der.content_01"
                        :content_02="der.content_02"
                        :content_03="der.content_03"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Heading from "../Elements/HeadingTitle.vue";
import Solution from "../Elements/Solution.vue";
import { reactive } from "vue";

export default {
  components: {
    Heading,
    Solution,
  },
  name: "dershare-solutions",
  setup() {
    const der_solutions = reactive([
      {
        title: "더쉐어",
        subtitle: `수많은 데이터를 스스로 처리하는 기술,`,
        mobile_subtitle: "수많은 데이터를<br/>스스로 처리하는 기술,",
        content_01: `전력피크를 방어하는 알고리즘, 전력중개시장 최적입찰, 실시간 Balancing 알고리즘도 서로 경합을 하여 Global Maximum Point를 찾아 운용 합니다. 세상의 알고리즘을 튜닝하고, 경합시켜 진화하는 플랫폼, Data Science에 집중합니다. 이들을 튜닝하여 라이브로 가져오며, 각 단계의 선정 변수, 경합지표, 프로세스를 엔지니어링 합니다.`,
        content_02: `태양광발전시설, ESS 자원을 하나의 가상발전소로 결합하고 거래하여 발전수익부터 전기요금절감, 전력중개시장 참여 인센티브를 공유합니다. 가상발전소 플랫폼을 통해 태양광발전소는 모니터링, 거래, 정산 비용을 낮추며, ESS는 전기요금절감 수익을 극대화할 수 있습니다.`,
        content_03: ``,
      },
      {
        title: "더쉐어 EDGE",
        subtitle: `ESS 최적 운용 서비스,`,
        content_01: `지급받던 한전인센티브가 끝나면 요금절감효과도 떨어지고 관리부담만 커집니다. DERShare.kr에서 인공지능이 알아서 요금도 절감해주고, 수시로 데이터 전문가가 직접 이상상황을 관리해 주니 운영, 관리 부담이 줄어듭니다.`,
        content_02: `최적운용을 통해 전력수요에 따라 전력을 충전 및 방전하여 전력 요금을 절감합니다. 전기요금 절감수익 극대화를 위해 전력피크 발생 추종 운전 및 전력부하시간대를 고려한 운전을 합니다.`,
        content_03: `설비담당자는 데이터 분석가가 아닙니다. 인공지능 플랫폼이 자동 운전하고, 데이터 전문가들이 분석을 통해 관리하는 Dershare.kr 최적운영 서비스를 도입하세요. 별도 도입비용 없이 추가전기요금 절감액의 일정부분만 지불하시면 됩니다. 앱/웹을 통해 운전현황과 수익 및 정산체계를 투명하게 제공합니다.`,
      },
    ]);
    return {
      der_solutions,
    };
  },
};
</script>
