<template>
  <section class="section-main">
    <swiper class="main-swiper" effect="fade" loop :speed="1000" :autoplay="{
      delay: 5000,
      disableOnInteraction: false,
    }" :slides-per-view="1">
      <swiper-slide v-for="item in 4" :key="item">
        <div class="inner-main">
          <div :class="`inner-main-image item-image-${item}`" :style="`height: ${fullscreen}px`">
            <div class="inner-main-box">
              <div class="box-item box-title">
                <h2>HAVE<br>YOUR ENERGY<br>& SHARE IT</h2>
              </div>
              <div class="box-item box-text">
                <div class="box-text-content" v-if="device_width < 1024">
                  <p>誰もがエネルギーの消費者であるだけではなく、エネルギーの生産者でもある、そんな未来に向けて、技術でエネルギーのエコシステムを革新していきます
                  </p>
                </div>
                <div class="box-text-content" v-else>
                  <p>誰もがエネルギーの消<br>費者であるだけではな<br>く、エネルギーの生産<br>者でもある、そんな未<br>来に向けて、技術でエ<br>ネルギーのエコシステ<br>ムを革新していきます</p>
                </div>
              </div>
              <div class="box-item box-btn" v-if="device_width > 1024">
                <div class="border-square" style="width: 140px"></div>
              </div>
              <div class="box-item box-btn" v-if="device_width > 1024">
                <div class="border-square" style="width: 500px"></div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import SwiperCore, { EffectFade, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref, reactive, onMounted, watch } from "vue";

SwiperCore.use([EffectFade, Autoplay])

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const fullscreen = ref(window.innerHeight);
    const main_images = reactive([
      { img: "img-mainvs01" },
      { img: "img-mainvs02" },
      { img: "img-mainvs03" },
      { img: "img-mainvs04" },
    ]);
    const youtube = ref(false);
    watch(() => youtube.value, (n) => {
      const style = n ? 'hidden' : 'scroll'
      document.documentElement.style.overflow = style
    })
    const close_modal = (v) => {
      console.log("youtube", v);
      youtube.value = v;
    }
    let device_width = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener('resize', () => {
        device_width.value = window.innerWidth;
        fullscreen.value = window.innerHeight;
      })
    })

    return {
      main_images,
      youtube,
      fullscreen,
      close_modal,
      device_width,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "swiper/swiper-bundle";

.section-main {
  height: 100vh;
  width: 100%;
}

.main-swiper {
  width: 100%;
  height: 100%;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-slide {
    width: 100%;
    height: 100vh;
  }
}

.box-text-content>p {
  word-break: normal !important;
  margin-left: 10px;
  margin-right: 10px;
  line-height: 55px;
}

.border-square {
  border-top: 1px solid white;
}
</style>
